import React, { useEffect, useMemo } from "react";

import { styled } from "../../../../stitches.config";
import RenderSlateChildren from "./RenderSlateChildren";
//import Title from "../Title"
//import Lead from "../Lead"
//import AuthorDate from "../AuthorDate"
import Picture from "./Picture";

import FadingPicture from "./FadingPicture";

import Adslot from "../../../Adslot";
import AdslotWithScrollWrapper from "../../../AdslotWithScrollWrapper";
import MarginWrapper from "../MarginWrapper";
import OnnetworkVideo from "../../Content/body/OnnetworkVideo";
import RelatedArticle from "../RelatedArticle";
import OrderedList from "./OrderedList";

import Youtube from "../Youtube";
import Link from "../../../Link";

// elementy bizon
import UnorderedListBizon from "../../../../redesign/List";
import SpecialText from "../../../../redesign/atoms/SpecialText";
import LeadBizon from "../../../../redesign/Lead";

import ArticleTitle from "../../../../redesign/ArticleTitle";
import AuthorDateBizon from "../../../../redesign/components/AuthorDate";
import AuthorBlock from "../../../../redesign/AuthorBlock";
import { p, h1, h2, h3 } from "../../../../redesign/commonStyles/Fonts";
import LinkChanger from "../../LinkChanger";

import SlateBlockBizon from "./SlateBlockBizon";
import RenderWrapper from "../../../RenderWrapper";
import { SlateRendererProps } from "./SlateRenderer";
import { SlateElementOrLegacyElement } from "./SlateRenderer";
import { useStores } from "../../../../helpers";
import TipFormStandalone from "../../../../redesign/Modal/payment/TipFormStandalone";
import TipCallToAction from "../../../../redesign/Modal/payment/TipCallToAction";
import Rating from '../Rating';
import Block from "../Block";

import TipWidget from "../../../../redesign/components/TipWidget";
import useMediaQuery from "../../../../helpers/useMediaQuery";

const MediaEmbed = React.lazy(() => import("./MediaEmbed"));

const CtaButton = React.lazy(() => import(/* webpackChunkName: "CtaButton" */"../../../../redesign/CtaButton"));
const PartnershipInfo = React.lazy(() => import(/* webpackChunkName: "PartnershipInfo" */"../../../../redesign/PartnershipInfo"));
const Gallery = React.lazy(() => import(/* webpackChunkName: "Slate__Gallery" */ "../../../../redesign/Gallery"));
const Poll = React.lazy(() => import(/* webpackChunkName: "Slate__Poll" */ "../Poll"));
const Quiz2 = React.lazy(() => import(/* webpackChunkName: "Slate__Quiz2" */ "../../../../redesign/components/Quiz2"));
const Contents = React.lazy(() => import(/* webpackChunkName: "Slate__Contents" */ "../../../../redesign/components/Contents"));
const Attachment = React.lazy(() => import(/* webpackChunkName: "Slate__Attachment" */ "../../../../redesign/components/Attachment"));
const Quote = React.lazy(() => import(/* webpackChunkName: "Slate__Quote" */ "../../../../redesign/Quote"));
const VehicleCard = React.lazy(() => import(/* webpackChunkName: "Slate__VehicleCard" */ "../../../../redesign/VehicleCard"));
const ExtraWideContent = React.lazy(() => import(/* webpackChunkName: "Slate__ExtraWideContent" */ "../../../../redesign/ExtraWideContent"));
const LiveItem = React.lazy(() => import(/* webpackChunkName: "Slate_LiveItem" */ "../LiveItem"));
const SocialIcons = React.lazy(() => import(/* webpackChunkName: "Slate__SocialIcons" */ "../../../../redesign/components/SocialIcons"));
const HtmlParser = React.lazy(() => import(/* webpackChunkName: "Slate__HtmlParser" */ "../../Content/HtmlParser"));
const MediaLinks = React.lazy(() => import(/* webpackChunkName: "Slate__MediaLinks" */ "../../../../redesign/MediaLinks"));
const HeaderPodcast = React.lazy(() => import(/* webpackChunkName: "Slate__HeaderPodcast" */ "../../../../redesign/HeaderPodcast"));
const SeeAlsoArticles = React.lazy(() => import(/* webpackChunkName: "Slate__SeeAlsoArticles" */ "../../../../redesign/components/SeeAlsoArticles"));
const PodcastPlayerWidget = React.lazy(() => import(/* webpackChunkName: "Slate__OnnetworkPodcastPlayer" */ "../../../../redesign/components/PodcastPlayer/PodcastPlayerWidget"));
const LiveSummary = React.lazy(() => import(/* webpackChunkName: "Slate__LiveSummary" */ "../../../../redesign/components/LiveSummary"));
const LiveDebate = React.lazy(() => import(/* webpackChunkName: "Slate__LiveDebate" */ "../../../../redesign/components/LiveDebate"));
const LiveMatchResult = React.lazy(() => import(/* webpackChunkName: "Slate__LiveMatchResult" */ "../../../../redesign/components/LiveMatchResult"));
const LiveGallery = React.lazy(() => import(/* webpackChunkName: "Content__body__Gallery" */"../../Content/body/Gallery"));

const SlateBlocksBizon: React.FC<SlateRendererProps> = ({
    parts,
    hideAdslots = false
}) => {
    const { ArticleStore } = useStores();
    const article = ArticleStore.article;
    const isPodcast = ArticleStore.article.display_type === "ARTICLE_PODCAST";
    const hasTip = !article.diasbleJournalistTip;

    const isDesktop = useMediaQuery('bp4');

    const isPWA = useMemo(() => window.matchMedia('(display-mode: standalone)').matches, []);

    useEffect(() => {
        var root = document.getElementById("root");
        root && root.classList.remove("loading");

        var acs = document.getElementById("articleContentInSource");
        if (acs) {
            /**
             * bez tego może być mignięcie na mobile
             */
            acs.classList.add("loaded");
            setTimeout(function () {
                document.getElementById("articleContentInSource")?.remove();
            }, 300);
        }
    }, []);

    return (
        <div style={{ whiteSpace: "pre-wrap" }}>
            <React.Suspense fallback={null}>
                {parts.map((el: SlateElementOrLegacyElement, index) => {
                    switch (el.type) {
                        case "cta-button":
                            return (
                                <SlateBlockBizon key={index}>
                                    {isDesktop && hasTip && <TipWidget author={ArticleStore.article.author} articleID={ArticleStore.article.OID} />}
                                    <RenderWrapper
                                        component={CtaButton}
                                        preventRerender
                                    />
                                </SlateBlockBizon>
                            );
                        case "post-title":
                            return (
                                <SlateBlockBizon key={index}>
                                    <RenderWrapper
                                        component={ArticleTitle}
                                        props={{
                                            as: (el as any).as,
                                            children: el.data
                                        }}
                                        preventRerender={(prev, next) => prev.children === next.children}
                                    />
                                </SlateBlockBizon>
                            )
                        case "author-date":
                            return (
                                <SlateBlockBizon key={index}>
                                    <RenderWrapper
                                        component={AuthorDateBizon}
                                        props={{
                                            article: article
                                        }}
                                        preventRerender={(prev, next) => prev.article.OID === next.article.OID}
                                    />
                                </SlateBlockBizon>
                            );
                        case "partnership-info":
                            return (
                                <SlateBlockBizon key={index}>
                                    {ArticleStore.article.BrandingPartnerInfoTop &&
                                        <PartnershipInfo partner={ArticleStore.article.BrandingPartnerInfoTop} />
                                    }
                                </SlateBlockBizon>
                            );
                        case "contents":
                            return (
                                <SlateBlockBizon key={index}>
                                    <Contents elements={el.data} />
                                </SlateBlockBizon>
                            );
                        case "social_icons":
                            return (
                                <SlateBlockBizon key={index}>
                                    <SocialIcons article={el.data} />
                                </SlateBlockBizon>
                            );
                        case "youtube":
                            return (
                                <SlateBlockBizon key={index} noSSR>
                                    <Youtube videoId={el.data} />
                                </SlateBlockBizon>
                            );
                        case "media_links":
                            return (
                                <SlateBlockBizon key={index}>
                                    <MediaLinks element={el.data} />
                                </SlateBlockBizon>
                            );
                        case "podcast_header":
                            return (
                                <SlateBlockBizon key={index}>
                                    <HeaderPodcast element={el.data} />
                                </SlateBlockBizon>
                            );
                        case "lead":
                            return (
                                <SlateBlockBizon key={index}>
                                    {!article.disableLead && (
                                        <RenderWrapper
                                            component={LeadBizon}
                                            props={{
                                                isPodcast: isPodcast,
                                                text: el.data
                                            }}
                                            preventRerender={(prev, next) => prev.isPodcast == next.isPodcast && prev.text == next.text}
                                        />
                                    )}
                                </SlateBlockBizon>
                            );
                        case "adslot":
                            if (hideAdslots) return null;
                            return (
                                <SlateBlockBizon key={index}>
                                    <MarginWrapper>
                                        <AdslotWithScrollWrapper
                                            {...el.data}
                                            disableFirework={article.disableFirework}
                                        />
                                    </MarginWrapper>
                                </SlateBlockBizon>
                            );
                        case "h1":
                            return (
                                <SlateBlockBizon key={index}>
                                    <RedesignMarginWrapper className={"parts--header--" + index}>
                                        {/* #2828 zmiana renderowania h1 -> h2 bez zmiany stylów */}
                                        <h2 className={h2()} id={el.anchor}>
                                            <RenderSlateChildren element={el.children} />
                                        </h2>
                                    </RedesignMarginWrapper>
                                </SlateBlockBizon>
                            );
                        case "h2":
                            return (
                                <SlateBlockBizon key={index}>
                                    <RedesignMarginWrapper className={"parts--header--" + index}>
                                        {/* #2828 zmiana renderowania h2 -> h3 bez zmiany stylów */}
                                        <h2 className={h2()} id={el.anchor}>
                                            <RenderSlateChildren element={el.children} />
                                        </h2>
                                    </RedesignMarginWrapper>
                                </SlateBlockBizon>
                            );
                        case "h3":
                            return (
                                <SlateBlockBizon key={index}>
                                    <RedesignMarginWrapper className={"parts--header--" + index}>
                                        {/* #2828 zmiana renderowania h3 -> h4 bez zmiany stylów */}
                                        <h3 className={h3()} id={el.anchor}>
                                            <RenderSlateChildren element={el.children} />
                                        </h3>
                                    </RedesignMarginWrapper>
                                </SlateBlockBizon>
                            );
                        case "p":
                            return (
                                <SlateBlockBizon key={index}>
                                    <BreakWordsWrapper className={"parts--paragraph--" + index}>
                                        <p style={{ whiteSpace: "pre-wrap" }} className={p()}>
                                            <RenderSlateChildren element={el.children} />
                                        </p>
                                    </BreakWordsWrapper>
                                </SlateBlockBizon>
                            );
                        case "fading-pictures":
                            return (
                                <SlateBlockBizon key={index}>
                                    <FadingPicture artIndex={index} data={el.data} />
                                </SlateBlockBizon>
                            );
                        case "picture":
                            return (
                                <SlateBlockBizon key={index}>
                                    <Picture artIndex={index} element={el} />
                                </SlateBlockBizon>
                            );
                        case "blockquote":
                            return (
                                <SlateBlockBizon key={index}>
                                    <Quote element={el} />
                                </SlateBlockBizon>
                            );
                        case "vehiclecard":
                            return (
                                <SlateBlockBizon key={index}>
                                    <VehicleCard element={el} article={article} />
                                </SlateBlockBizon>
                            );
                        case "block":
                            return (
                                <SlateBlockBizon key={index}>
                                    <Block settings={el.data} article={article} hideAdslots={hideAdslots} />
                                </SlateBlockBizon>
                            );
                        case "wide_content":
                            return (
                                <SlateBlockBizon key={index}>
                                    <ExtraWideContent element={el} />
                                </SlateBlockBizon>
                            );
                        case "gallery":
                            return (
                                <SlateBlockBizon key={index}>
                                    <Gallery pictures={el.data} />
                                </SlateBlockBizon>
                            );
                        case "similar_articles":
                            return (
                                <SlateBlockBizon>
                                    <SeeAlsoArticles articles={el.data} boxSize="big" />
                                </SlateBlockBizon>
                            );
                        case "poll":
                            return (
                                <SlateBlockBizon key={index}>
                                    <Poll data={el.data} />
                                </SlateBlockBizon>
                            );
                        case "quiz2":
                            return (
                                <SlateBlockBizon key={index}>
                                    <Quiz2 data={el.data} element={el} />
                                </SlateBlockBizon>
                            );
                        case "media_embed":
                            return (
                                <SlateBlockBizon key={index} noSSR>
                                    <MediaEmbed element={el} />
                                </SlateBlockBizon>
                            );
                        case "element_frame":
                            return (
                                <SlateBlockBizon key={index}>
                                    <SpecialText element={el} />
                                </SlateBlockBizon>
                            );
                        case "ul":
                            return (
                                <SlateBlockBizon key={index}>
                                    <UnorderedListBizon element={el} />
                                </SlateBlockBizon>
                            );
                        case "ol":
                            return (
                                <SlateBlockBizon key={index}>
                                    <OrderedList element={el} />
                                </SlateBlockBizon>
                            );
                        case "onnetwork":
                            if (hideAdslots) return null;

                            return (
                                <SlateBlockBizon key={index} noSSR>
                                    <OnnetworkVideo
                                        mid={el.data.id}
                                        hookPlayer={el.data.hookOnNetworkPlayer}
                                    />
                                </SlateBlockBizon>
                            );
                        case "related_article":
                            if (!el.data) return null;
                            return (
                                <SlateBlockBizon key={index}>
                                    <RelatedArticle article={el.data} />
                                </SlateBlockBizon>
                            );
                        case "html":
                            return (
                                <SlateBlockBizon key={index}>
                                    <HtmlParser data={el.data} />
                                </SlateBlockBizon>
                            );
                        case "attachment":
                            return (
                                <SlateBlockBizon key={index}>
                                    <Attachment element={el} />
                                </SlateBlockBizon>
                            );
                        case "code_block":
                            return (
                                <SlateBlockBizon key={index}>
                                    <>
                                        {el.children.map((codeBlock, codeBlockIndex) => {
                                            return (
                                                <HtmlParser
                                                    data={codeBlock.text}
                                                    key={"code_block_" + codeBlockIndex}
                                                />
                                            );
                                        })}
                                    </>
                                </SlateBlockBizon>
                            );
                        case "live-item":
                            return (
                                <SlateBlockBizon key={index}>
                                    <LiveItem
                                        settings={el.data}
                                        article={article}
                                        hideAdslots={hideAdslots}
                                    />
                                </SlateBlockBizon>
                            );
                        case "onnetwork-podcast-player":
                            return (
                                <SlateBlockBizon key={index}>
                                    <PodcastPlayerWidget data={el.data} noSSR />
                                </SlateBlockBizon>
                            );
                        case "link_changer":
                            return (
                                <SlateBlockBizon key={index}>
                                    <LinkChanger
                                        msg="article changer"
                                        url={article.url}
                                        data={el.data}
                                    >
                                        <div style={{ width: 1, height: 1, marginBottom: -18 }} />
                                    </LinkChanger>
                                </SlateBlockBizon>
                            );
                        case "element_tip":
                            return (
                                <SlateBlockBizon key={index}>
                                    {/* @ts-ignore */}
                                    <TipCallToAction
                                        headText={`Podobają Ci się moje artykuły? Możesz zostawić napiwek`}
                                        descriptionText="Teraz możesz docenić pracę dziennikarzy i dziennikarek. Cała kwota trafi do nich. Wraz z napiwkiem możesz przekazać też krótką wiadomość."
                                        articleOID={article.OID}
                                        users={article.allAuthors}
                                        origin={"tip-inline"}
                                    />
                                </SlateBlockBizon>
                            );
                        case "redactor":
                            if (!el.data) return null;
                            return (
                                <SlateBlockBizon key={index}>
                                    <AuthorBlock author={el.data} border={true} isRedactor={true} authorSize="big" />
                                </SlateBlockBizon>
                            );
                        case "rating":
                            return (
                                <SlateBlockBizon key={index}>
                                    <Rating key={index} rating={el.data} />
                                </SlateBlockBizon>
                            );

                        case 'body-react-gallery':
                            return (
                                <SlateBlockBizon key={index}>
                                    <LiveGallery settings={el.data} />
                                </SlateBlockBizon>
                            );
                        case "debate":
                            return (
                                <SlateBlockBizon key={index}>
                                    <LiveDebate data={el.data} />
                                </SlateBlockBizon>
                            );
                        case "match_result":
                            return (
                                <SlateBlockBizon key={index}>
                                    <LiveMatchResult data={el.data} />
                                </SlateBlockBizon>
                            );
                        case "live_summary":
                            return (
                                <SlateBlockBizon key={index}>
                                    <LiveSummary data={el.data} />
                                </SlateBlockBizon>
                            );
                        default:
                            return null;
                    }
                })}
            </React.Suspense>
        </div>
    );
};

const RedesignMarginWrapper = styled("div", {
    marginTop: "32px",
    "@bp4": {
        marginTop: "56px",
    },
});

const BreakWordsWrapper = styled("div", {
    "& p": {
        overflowWrap: "break-word",
    },
});

export default SlateBlocksBizon;
